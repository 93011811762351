<template>
	<div class="mt-7">
		<div
			v-if="!register && showFullPasswordForm && showPasskeyDisclaimer"
			class="passkey-promo mb-5">
			<img
				src="../assets/passkey-logo-blue.svg"
				alt="passkey icon">
			<div>
				<span
					class="body-3"
					:class="$vuetify.theme.dark ? 'grayscale100--text' : 'grayscale800--text'">
					{{ $t('password_login.passkey_promo_1') }}
				</span>
				<a
					class="body-3 primary-text-link primary-text-link--underline cursor-pointer"
					:href="computedLink"
					target="_blank">{{ $t('password_login.passkey_promo_2') }}
				</a>
			</div>
		</div>
		<ValidationObserver v-slot="{handleSubmit}">
			<form
				v-if="!register && !showFullPasswordForm"
				data-form-type="login"
				@submit.prevent="handleSubmit(proceed)">
				<ValidationProvider
					v-slot="{errors}"
					mode="passive"
					name="email"
					rules="required"
					slim>
					<div class="py-4">
						<e-text-field
							v-model="username"
							:type="usernameType"
							large=""
							:label="$i18n.t('password_login.email')"
							:error="errors[0] && errors[0] !== ''"
							:error-messages="errors[0]"
							data-form-type="username"
							autocomplete="username"
							name="username" />
					</div>
				</ValidationProvider>
				<e-button
					class="callout-3 mb-4"
					:block="true"
					type="submit"
					variant="primary"
					size="large"
					large>
					{{ $t('password_login.continue_with_password') }}
				</e-button>
			</form>
		</ValidationObserver>
		<ValidationObserver v-slot="{handleSubmit}">
			<form
				v-if="!register && showFullPasswordForm"
				data-form-type="login"
				autocomplete="on"
				@submit.prevent="passwordLoginBusy? '' : handleSubmit(login)">
				<ValidationProvider
					v-slot="{errors}"
					mode="passive"
					name="email"
					rules="required"
					slim>
					<div class="py-4">
						<e-text-field
							v-model="username"
							:type="usernameType"
							large=""
							:label="$i18n.t('password_login.email')"
							:error="errors[0] && errors[0] !== ''"
							:error-messages="errors[0]"
							data-form-type="username"
							autocomplete="username"
							name="username" />
					</div>
				</ValidationProvider>
				<ValidationProvider
					v-slot="{errors}"
					mode="passive"
					name="password"
					rules="required"
					class="mt-6 mb-4"
					slim>
					<div>
						<e-text-field
							v-model="password"
							large=""
							:label="$i18n.t('password_login.password')"
							:type="showPassword ? 'text' : 'password'"
							:appendIcon="showPassword ? 'icon-eye-cut' : 'icon-eye'"
							:error="errors[0] && errors[0] !== ''"
							:error-messages="errors[0]"
							data-form-type="password"
							autocomplete="current-password"
							name="password"
							@click:append="showPassword = !showPassword" />
					</div>
				</ValidationProvider>


				<div class="d-block text-center mx-auto">
					<vue-recaptcha
						v-show="passwordShowRecaptcha"
						ref="recaptcha"
						class="recaptcha"
						:sitekey="recaptcha.siteKey"
						size="normal"
						:load-recaptcha-script="true"
						@verify="onVerify" />
				</div>
				<e-button
					variant="link"
					small=""
					:to="'/' + locale + '/forgotPassword'">
					{{ $t("password_login.forgot_password") }}
				</e-button>
				<e-button
					class="mt-4 callout-3"
					:block="true"
					type="submit"
					:loading="passwordLoginBusy && !register"
					:disabled="disableButton"
					variant="primary"
					size="large"
					large>
					{{ $t('password_login.login') }}
				</e-button>
			</form>
		</ValidationObserver>
		<ValidationObserver v-slot="{handleSubmit}">
			<form
				v-if="register"
				data-form-type="login"
				@submit.prevent="handleSubmit(toRegistration)">
				<ValidationProvider
					v-slot="{errors}"
					mode="passive"
					name="email"
					rules="required"
					slim>
					<div class="py-4">
						<e-text-field
							v-model="username"
							:type="usernameType"
							large=""
							:label="$i18n.t('password_login.email')"
							:error="errors[0] && errors[0] !== ''"
							:error-messages="errors[0]"
							data-form-type="username"
							name="username" />
					</div>
				</ValidationProvider>
				<e-button
					class="callout-3 mb-4"
					:block="true"
					type="submit"
					variant="primary"
					size="large"
					large>
					{{ $t('password_login.continue_with_password') }}
				</e-button>
			</form>
		</ValidationObserver>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import VueRecaptcha from "vue-recaptcha";
import { getEnv } from '../env';
export default {
	components: {
		VueRecaptcha,
	},
	props: {
		register: Boolean,
		showFullPasswordForm: Boolean,
	},
	data () {
		return {
			lang: this.locale,
			//showTwoFactor: false,
			codeSent: false,
			recaptcha: {
				recaptcha: "",
				siteKey: getEnv('VUE_APP_FRONTEND_RECAPTCHA_SITE_KEY'),
			},
			username: "",
			password: "",
			recaptchaCode: "",
			twoFactor: "",
			showPassword: false,
			usernameType: "username",
		};
	},

	computed: {
		locale (){
			return this.$i18n.locale;
		},
		...mapGetters([
			'passwordLoginBusy',
			'passwordSuccess',
			'passwordError',
			'passwordLoggedIn',
			'passwordUrl',
			'passwordShowRecaptcha',
			'passwordShowTwoFactor',
			'passwordRedirect',
			'passwordErrors',
			'twoFactorBusy',
			'twoFactorError',
			'twoFactorErrors',
			'twoFactorSuccess',
			'termsRequired',
			'CFTurnstileToken',
			'CFTurnstileWidgetId',
			'disableButton',
		]),
		...mapState ([
			'miniView',
		]),
		showPasskeyDisclaimer (){
			const allowedMethods = getEnv('VUE_APP_FRONTEND_THIRD_PARTY_ALLOWED_METHODS').split(", ")
			return allowedMethods.includes('passkey')
		},
		computedLink (){
			return this.$i18n.locale == 'en'
				? 'https://help.ekool.eu/en_US/passkey'
				: 'https://help.ekool.eu/et_ET/turvavoti';
		},
	},
	mounted () {
		this.initError();
	},
	beforeDestroy () {
		/* eslint-disable no-undef */
		turnstile.remove(this.CFTurnstileWidgetId)
	},
	methods: {
		login () {
			var email = this.username;
			var password = this.password;
			var recaptcha = this.recaptcha.recaptcha;
			var twoFactorCode = this.twoFactor;
			var token = this.CFTurnstileToken;
			this.$store.dispatch('loginUser', { email, password, recaptcha, twoFactorCode, token }).then(
				response => {
					/* eslint-disable no-undef */
					turnstile.reset(this.CFTurnstileWidgetId)
					if(response.data.session_active === false){
						this.username = ""
						this.password = ""
						this.recaptcha.recaptcha = ""
						this.twoFactor = ""
					}
					if(this.passwordShowRecaptcha){
						this.resetRecaptcha();
					}
					else if(this.passwordLoggedIn && this.passwordUrl !== undefined){
						if (this.miniView) {
							this.postLoginMessage('success')
							return
						}
						//iframe login
						if(this.passwordRedirect == "false"){
							this.postLoginMessage('success')
							return
						}
						window.top.location.href = this.passwordUrl;
					}
					if(this.passwordError){
						this.$store.commit("setDisableButton",true);
						if(this.passwordErrors == 'check_email_and_password'){
							this.$store.commit(
								"changeError",
								"password_login.submit_error",
							);
							this.$store.commit("showError", true);
						}
						else if(this.passwordErrors == 'recaptcha_required' || this.passwordErrors == 'recaptcha_failed'){
							this.$store.commit("changeError",
								("setup_account.recaptcha_failed"));
							this.$store.commit("showError",true);
						}
						else{
							if(this.$te("password_login." + this.passwordErrors)){
								this.$store.commit("changeError",
									("password_login." + this.passwordErrors));
								this.$store.commit("showError",true);
							}
							else{
								this.$store.commit("changeError",
									("repeating_errors." + this.passwordErrors));
								this.$store.commit("showError",true);
							}
						}

					}
					if(this.termsRequired){
						this.$router.push({
							name: "agreeToTerms",
						});
					}
					if(this.passwordShowTwoFactor){
						this.$router.push({
							name: "twoFactorOptions",
						})
					}
				},

			)

		},

		sendTwoFactor () {
			var recaptcha = this.recaptcha.recaptchaCode;
			var twoFactorCode = this.twoFactor;
			this.$store.dispatch('sendTwoFactor', { twoFactorCode, recaptcha }).then(
				response => {
					if(this.termsRequired){
						this.$router.push({
							name: "agreeToTerms",
						});
					}
					if(this.twoFactorSuccess && !this.termsRequired){
						if (this.miniView) {
							this.postLoginMessage('success')
							return
						}
						window.top.location.href = response.body.url;
					}
					else if(this.twoFactorError && this.twoFactorErrors !== undefined){
						this.$store.commit(
							"changeError",
							("password_login." + this.twoFactorErrors),
						);
						this.$store.commit("showError", true);
					}else if(this.twoFactorError){
						this.$store.commit(
							"changeError",
							"password_login.submit_error",
						);
						this.$store.commit("showError", true);
					}
				},

			)
		},
		initError () {
			this.$store.getError;
		},
		togglePasswordVisibility () {
			this.passwordType =
        this.passwordType === "password" ? "text" : "password";
		},
		onVerify (response) {
			this.recaptcha.recaptcha = response;
		},
		resetRecaptcha () {
			this.recaptcha.showRecaptcha = true;
			this.$refs.recaptcha.reset(); // Direct call reset method
		},
		proceed () {
			this.$emit('showFullForm', true);
		},
		passkeyInfo () {
			console.log('redirect to passkey info page');
		},
		toRegistration () {
			this.$router.push({
				name: "setupAccount",
				params: {
					user: { email: this.username },
					allowEdit: true,
				},
			})
		},
	},
};
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
